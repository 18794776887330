.App {
    text-align: center;
}

.App-logo {
    height: 11rem;
    pointer-events: none;
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 700;
}
